import { Typography, Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import StrapiLink from '@templates/strapi-cms/content-types/Link'

export const Heading2Wrapper = styled('div')(({ theme, headingStyles }) => ({
  marginBottom: headingStyles?.marginBottom ?? '25px',
  [theme.breakpoints.down('md')]: {
    marginBottom: headingStyles?.marginBottomMobile ?? '16px',
  },
}))

export const Title = styled(Typography, {
  shouldForwardProp: prop => prop !== 'headingStyles',
})(({ theme, headingStyles }) => ({
  textTransform: 'none',
  '&.MuiTypography-root': {
    fontWeight: headingStyles?.fontWeight ?? 500,
    fontSize: headingStyles?.fontSize ?? '25px',
    color: '#003566',
    margin: 0,
    paddingRight: '10px',
    lineHeight: 'normal',
    letterSpacing: headingStyles?.letterSpacing ?? 'normal',
    [theme.breakpoints.down('md')]: {
      fontSize: headingStyles?.fontSizeMobile ?? '20px',
    },
  },
}))

export const TitleAndSeeAllWrapper = styled('div')(({ hasTitle }) => ({
  display: hasTitle ? 'flex' : 'block',
  alignItems: 'center',
  flexWrap: 'wrap',
}))

export const LinkDivider = styled('div')(({ theme, headingStyles }) => {
  let fontSize = '25px'
  let fontSizeMobile = '16px'
  if (headingStyles?.fontSize) {
    const fontInt = parseInt(headingStyles.fontSize.replace('px', ''))
    fontSize = `${fontInt - 5}px`
  }
  if (headingStyles?.fontSizeMobile) {
    const fontInt = parseInt(headingStyles.fontSizeMobile.replace('px', ''))
    fontSizeMobile = `${fontInt - 5}px`
  }

  return {
    fontSize,
    fontWeight: 600,
    color: '#003566',
    marginBottom: '2px',
    paddingRight: '10px',
    [theme.breakpoints.down('md')]: {
      fontSize: fontSizeMobile,
    },
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  }
})

export const SeeAllLinkAndDividerWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    flexBasis: '100%',
  },
}))

export const SeeAllLink = styled(StrapiLink, {
  shouldForwardProp: prop => !['headingStyles', 'subheadingMobile'].includes(prop),
})(({ theme, headingStyles, subheadingMobile }) => ({
  fontSize: headingStyles?.fontSize ?? '25px',
  fontWeight: 400,
  color: '#0053A0 !important',
  textTransform: 'uppercase',
  transition: theme.transitions.create(['color'], {
    easing: theme.transitions.easing.easeIn,
    duration: theme.transitions.duration.short,
  }),
  '&:hover': {
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
  '& svg.link-arrow-next': {
    right: '-2.2rem',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '16px',
  },
  [theme.breakpoints.down('sm')]: {
    display: subheadingMobile === 'description' ? 'none' : 'block',
  },
}))

export const Description = styled(Typography, {
  shouldForwardProp: prop => !['isViewAll', 'subheadingMobile'].includes(prop),
})(({ theme, subheadingMobile, isViewAll }) => ({
  textTransform: 'none',
  '&.MuiTypography-root': {
    fontWeight: 400,
    color: '#003566',
    margin: 0,
    lineHeight: 'normal',
    marginTop: isViewAll ? '-3px' : 0,
    [theme.breakpoints.down('md')]: {
      fontSize: '14px',
    },
  },
  [theme.breakpoints.down('sm')]: {
    display: subheadingMobile === 'link' || subheadingMobile === null ? 'none' : 'block',
    marginTop: 0,
  },
}))

export const Heading1Wrapper = styled('div')(({ theme, headingStyles }) => ({
  marginBottom: headingStyles?.marginBottom ?? '32px',
  [theme.breakpoints.down('md')]: {
    marginBottom: '16px',
  },
}))

export const TitleStyles = styled(Typography)(({ theme }) => ({
  fontSize: '40px',
  fontWeight: '600',
  color: '#003566',
  padding: '20px 0 10px',
  [theme.breakpoints.down('sm')]: {
    fontSize: '27px',
    padding: '15px 0 10px',
  },
}))

export const DescriptionDesktopStyles = styled(Box)(({ theme }) => ({
  color: '#333333',
  fontSize: '16px',
  fontWeight: '400',
  height: '20px',
  marginBottom: '20px',
  overflow: 'hidden',
}))

export const DescriptionMobileStyles = styled(Box)(({ theme }) => ({
  color: '#333333',
  fontSize: '16px',
  fontWeight: '400',
  height: '45px',
  marginBottom: '20px',
  overflow: 'hidden',
}))

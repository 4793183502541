import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { styled } from '@mui/material'

const StrapiVideo = ({ data, videoProps, isFullWidth }) => {
  const { Alignment, Autoplay, Controls, Height, Loop, Muted, Thumbnail, Video, Width, ShowDesktop, ShowMobile } =
    data.Video || {}

  const StyledDiv = styled('div')(({ theme }) => ({
    width: '100%',
    textAlign: Alignment?.toLowerCase(),
    video: {
      width: `${videoProps?.width ?? (Width || 'auto')} !important`,
      height: videoProps?.height ?? (Height || 'auto'),
      maxWidth: '100%',
    },
    display: ShowDesktop === false ? 'none' : 'flex',
    [theme.breakpoints.down('md')]: {
      display: ShowDesktop === false ? 'none' : 'flex',
      video: {
        width: '100% !important',
        height: 'auto',
      },
    },
    [theme.breakpoints.down('sm')]: {
      display: ShowMobile === false ? 'none' : 'flex',
    },
  }))

  return (
    // muted attr on <video> doesn't work properly in React, so need to dangerouslySetInnerHTML
    <StyledDiv
      className="video-box"
      dangerouslySetInnerHTML={{
        __html: `
        <video
          ${Controls || videoProps?.controls ? 'controls' : ''}
          ${Autoplay || videoProps?.autoplay ? 'autoPlay' : ''}
          ${Muted || videoProps?.muted ? 'muted' : ''}
          ${Loop || videoProps?.loop ? 'loop' : ''}
          poster=${Thumbnail?.url}
          controlslist="nodownload noplaybackrate nofullscreen"
          disablepictureinpicture
          playsinline
          style="width: ${isFullWidth ? '100% !important;' : 'auto'}"
        >
          <source src=${Video?.url} type="video/mp4" />
        </video>
      `,
      }}
      ShowDesktop={ShowDesktop}
      ShowMobile={ShowMobile}
    />
  )
}

StrapiVideo.propTypes = {
  data: PropTypes.object.isRequired,
  videoProps: PropTypes.object,
  isFullWidth: PropTypes.bool,
}

export default StrapiVideo

export const videoFragment = graphql`
  fragment StrapiPageVideoFragment on StrapiVideo {
    id
    Alignment
    Autoplay
    Controls
    Height
    Loop
    Muted
    Thumbnail {
      url
    }
    Video {
      url
    }
    Width
    ShowDesktop
    ShowMobile
  }
`

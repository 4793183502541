// dependencies
import React from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
// components
import SingleSection from './SingleSection'

const StrapiSectionsBanner = ({ data, hasMainBannerImage = {}, bannerHeight, isFullWidth }) => {
  const sections = data || []
  return (
    <>
      {sections.map(({ BannerSection }) =>
        BannerSection ? (
          <SingleSection
            BannerSection={BannerSection}
            key={BannerSection.id}
            hasMainBannerImage={hasMainBannerImage}
            bannerHeight={bannerHeight}
            isFullWidth={isFullWidth}
          />
        ) : null,
      )}
    </>
  )
}

StrapiSectionsBanner.propTypes = {
  data: PropTypes.array.isRequired,
  hasMainBannerImage: PropTypes.object,
  bannerHeight: PropTypes.number,
  isFullWidth: PropTypes.bool,
}

export default StrapiSectionsBanner
